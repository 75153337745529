<template>
  <div>
    <div style="height: 100% mt-8" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="mt-8" v-else>
      <div class="flex">
        <p class="text-lg font-bold flex-grow capitalize text-darkPurple">
          {{ ` ${adminSettings.fname} ${adminSettings.lname}` }}
        </p>
        <span
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="handleClick" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
        </span>
      </div>
      <image-upload
        @file="setPhoto($event)"
        :photo="adminSettings.photo"
        :name="`${adminSettings.fname} ${adminSettings.lname}`"
      />
      <div class="grid grid-cols-12 gap-7 mt-6">
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="First name"
            v-model="adminSettings.fname"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            label="Middle name"
            v-model="adminSettings.mname"
            variant="w-full"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            label="Last name"
            v-model="adminSettings.lname"
            variant="w-full"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Gender"
            v-model="adminSettings.gender"
            :options="genders"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Email Address"
            v-model="adminSettings.email"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Phone number"
            v-model="adminSettings.phoneNo"
          />
        </div>
      </div>
      <div class="borderLine my-8" />
      <!----------------------------------------------
                    security
     ---------------------------------------------->
      <p class="font-semibold capitalize text-darkPurple">Security Settings</p>
      <div class="grid grid-cols-12 gap-7 mt-6" v-if="this.$AuthUser.isRoot">
        <div class="col-span-4">
          <div class="flex">
            <p class="flex flex-grow text-sm" style="margin-bottom: 7px">
              Password
            </p>
          </div>
          <Button
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn rounded"
            height="40px"
            width="100%"
            :disabled="!edit"
            @click="PasswordModal = true"
          >
            Update Password
          </Button>
        </div>
        <div class="col-span-4" v-feature-request>
          <div class="flex">
            <p class="flex flex-grow text-sm" style="margin-bottom: 7px">
              Security Question
            </p>
          </div>
          <Button
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn rounded"
            height="40px"
            width="100%"
            :disabled="!edit"
            @click="QuestiionsModal = true"
          >
            Configure
          </Button>
        </div>

        <div class="col-span-4">
          <div class="flex">
            <p class="flex flex-grow text-sm" style="margin-bottom: 7px">
              Two Factor Authentication
            </p>
            <p class="text-xs text-mediumSeaGreen font-bold justify-end">
              {{ twoFAEnabled }}
            </p>
          </div>
          <Button
            v-if="twoFAEnabled === 'Enabled'"
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn rounded"
            height="40px"
            width="100%"
            :disabled="!edit"
            @click="TwoFAModal = true"
          >
            Update
          </Button>
          <Button
            v-else
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn rounded"
            height="40px"
            width="100%"
            :disabled="!edit"
            @click="TwoFAModal = true"
          >
            Configure
          </Button>
        </div>
      </div>
      <div class="borderLine my-8" />
      <div
        class="mt-16 mb-6 w-full flex justify-between items-center"
        v-if="this.$AuthUser.isRoot"
      >
        <div class="flex" v-if="edit">
          <Button
            class="bg-dynamicBackBtn text-white my-6 w-40 flex rounded"
            @click="handleSaveAdminSettings()"
            :disabled="disableBtn"
          >
            Save changes
          </Button>
          <Button
            class="text-darkPurple my-6 w-40 flex rounded"
            @click="edit = false"
          >
            Cancel
          </Button>
        </div>
        <button
          v-if="edit"
          class="flex items-center text-xs"
          @click="TransferAdmin()"
        >
          <Icon icon-name="keys_transfer" size="xs" />
          <span class="ml-2 font-bold">Transfer Admin Rights</span>
        </button>
      </div>
      <ChangePassword
        :open-modal="PasswordModal"
        @close="PasswordModal = false"
      />
      <SecurityQuestion
        :open-modal="QuestiionsModal"
        @close="QuestiionsModal = false"
      />
      <TwoFA
        :open-modal="TwoFAModal"
        :status="this.$store.twoFactorStatus"
        @close="TwoFAModal = false"
        @statusInfo="setTwoFAStatus($event)"
        @responseData="setResponse($event)"
      />
      <TransferRights
        :open-modal="TransferModal"
        @close="TransferModal = false"
        @locked="lockTransferModal()"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import imageUpload from "@/components/imageUpload";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import ChangePassword from "../sidebar/ChangePassword";
import SecurityQuestion from "../sidebar/SecurityQuestion";
import TwoFA from "../sidebar/2FA";
import TransferRights from "../sidebar/TransferRights";

export default {
  components: {
    imageUpload,
    CText,
    Icon,
    CSelect,
    ChangePassword,
    SecurityQuestion,
    TwoFA,
    TransferRights,
    Button,
  },
  name: "Admin",
  data() {
    return {
      disableBtn: false,
      edit: false,
      loading: true,
      TransferModal: false,
      TwoFAModal: false,
      QuestiionsModal: false,
      PasswordModal: false,
      disableTransferModal: false,
      middleName: "",
      photo: "",
      response: "",
      responseBg: "",
      success: false,
      twoFAEnabled: "",
      adminSettings: {
        photo: "",
        fname: "",
        mname: "",
        lname: "",
        gender: "",
        email: "",
        phoneNo: "",
      },
      genders: ["Male", "Female"],
      authPassword: "",
    };
  },
  watch: {
    responseBg() {
      setTimeout(() => {
        this.response = "";
      }, 5000);
    },
  },
  computed: {
    ...mapState({
      rootUser: (state) => state.org.rootUser,
      twoFactorStatus: (state) => state.org.twoFactorStatus,
      activeSecurityQuestions: (state) => state.org.activeSecurityQuestions,
      securityQuestions: (state) => state.org.securityQuestions,
    }),
    formatActiveSecurityQuestion() {
      if (this.activeSecurityQuestions.length)
        return this.activeSecurityQuestions[0].question;
      return "";
    },
  },
  methods: {
    ...mapActions({
      updateRootUser: "org/updateRootUser",
      sendTwoFactorCode: "org/sendTwoFactorCode",
      sendAdminTwoFactorCode: "org/sendAdminTwoFactorCode",
      enableTwoFactor: "org/enableTwoFactor",
      disableTwoFactor: "org/disableTwoFactor",
      transferAdminRights: "org/transferAdminRights",
    }),
    handleClick() {
      if (this.adminSettings.fname === this.$AuthUser.fname) {
        this.edit = !this.edit;
      } else {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleSaveAdminSettings() {
      this.disableBtn = true;
      const promise = this.updateRootUser(this.adminSettings);
      // eslint-disable-next-line no-unused-vars
      promise.then((result) => {
        this.response = "profile settings updated successfully";
        this.responseBg = "bg-mediumSeaGreen";
        this.edit = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.disableBtn = false;
      });
      this.userEditable = false;
    },
    cancelAdminSettings() {
      const adminSettings = this.$store.dispatch("org/getRootUser");
      adminSettings.then((result) => {
        this.adminSettings = result.user;
      });
      this.disableBtn = false;
    },
    setPhoto(value) {
      this.photo = value;
      this.adminSettings.photo = value;
    },
    getRootName(value) {
      if (value.mname === null) {
        this.middleName = "";
      } else {
        this.middleName = value.mname;
      }
    },

    setTwoFAStatus(status) {
      if (status === "true" || status) {
        this.twoFAEnabled = "Enabled";
        this.$store.twoFactorStatus = String(status) === "true";
      }
      if (status === "false" || !status) {
        this.twoFAEnabled = "";
        this.$store.twoFactorStatus = String(status) === "true";
      }
    },

    setResponse(status) {
      this.responseBg = "bg-mediumSeaGreen";
      this.response = status;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    lockTransferModal() {
      this.disableTransferModal = true;
    },

    TransferAdmin() {
      if (this.disableTransferModal) {
        this.$toasted.error(
          "You have not profiled any admin (user) to transfer admin rights to",
          { duration: 5000 }
        );
      } else {
        this.TransferModal = true;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("org/getRootUser");
    await this.$store.dispatch("org/getTwoFactorStatus");
    this.$store.dispatch("org/getActiveSecurityQuestions");
    this.adminSettings = this.$store.getters["org/rootUser"];
    this.getRootName(this.adminSettings);
    this.setTwoFAStatus(this.twoFactorStatus);
    this.loading = false;
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
