<template>
    <div class="flex flex-col w-full my-6">
        <div class="my-4">
        <Table
          :headers="orgHeader"
          :items="accountsData"
          aria-label="advance Table"
          class="w-full my-3"
          :has-checkbox="true"
          has-number
          v-if="accountsData.length > 0"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.name">
              {{ item.data.name }}
            </div>
            <div v-else-if="item.email">
              {{ item.data.email }}
            </div>
            <div v-else-if="item.country">
              {{ item.data.country }}
            </div>
            <div v-else-if="item.domain">
              {{ item.data.domain }}
            </div>
            <div v-else-if="item.synced" class="mr-4 text-center">
              {{item.data.synced}}
            </div>
            <div v-else-if="item.unsynced" class="mr-4 text-center">
              {{item.data.unsynced}}
            </div>
            <div v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                  <div class="w-48 flex flex-col justify-start items-center gap-4 px-3 py-3">
                    <div class="w-full">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer" @click="reactivateOrg(item.data)">
                        <Icon icon-name="reactivate" class-name="text-mediumSeaGreen" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Reactivate</p>
                      </div>
                    </div>
                  </div>
                </Menu>
            </div>
            </template>
            </Table>
            </div>

    </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@/components/Menu";

export default {
    name: "InActive",
    components: {
        Menu,
        Table
    },
    data() {
        return {
            orgHeader: [
        { title: "Account Name", value: "name" },
        { title: "Country", value: "country" },
                { title: "Domain Name", value: "domain" },
                { title: "Email", value: "email" },
                { title: "Created/Synced", value: "synced" },
                { title: "Unsynced", value: "unsynced" },
        { title: "", value: "id", image: true }
      ],
            accountsData: [],
            groupOrg: false
        }
    },
    props: {
        orgAccounts: {
            type: Array,
            default: () => []
        }
  },

  methods: {
    reactivateOrg(data) {
      const payload = {
        userId: data.userId,
        orgId: data.id
      };
      this.$_reactivateOrganization(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.$emit('change', true)
      })
    }
  },

    created() {
        this.orgAccounts.forEach((account) => {
            this.accountsData.push({
                name: account.organisation.name,
              id: account.organisation.id,
                userId: account.userId,
                email: account.organisation.email,
                country: account.country,
                domain: account.organisation.alias,
                synced: account.organisation.syncedDate ? this.$DATEFORMAT(new Date(account.organisation.syncedDate), "MMMM dd, yyyy") : '---',
                unsynced: account.organisation.unsyncedDate ?  this.$DATEFORMAT(new Date(account.organisation.unsyncedDate), "MMMM dd, yyyy") : '---',
                status: account.subscriptionStat
            })
        })
    }
}
</script>