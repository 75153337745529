<template>
  <RightSideBar
    v-if="openModal"
    @close="closeBar()"
    :loading="loading"
    close-button
    button-class="bg-dynamicBackBtn text-white rounded"
  >
    <template v-slot:title>
      <p class="text-darkPurple font-extrabold text-lg">
        Change Phone Number
      </p>
    </template>
    <div>
        <div :class="['flex flex-col items-start gap-3', visibility.first? '':'opacity-50' ]">
          <p class="text-darkPurple text-base">
            Enter Password
          </p>
          <p class="text-darkPurple text-sm">
            Input account password to proceed.
          </p>
          <span
            class="text-red-500 mr-4"
            v-if="error.first"
          >Incorrect Password</span>
          <CText
            class="w-full"
            :variant="error.first?'error':''"
            placeholder="Password"
            :disabled="!visibility.first"
            v-model="password"
            :type="passwordText"
          />
          <Button
            @click="checkPassword"
            :disabled="!visibility.first"
            :class="[' flex', visibility.first? 'text-flame':'text-jet' ]"
          >
            <span class="mr-24">
              Proceed
            </span>
          </Button>
          <checkbox
            label="Show Password"
            checkbox-size="width:16px; height: 16px; margin-top: 3%"
            class="flex justify-center items-center"
            v-model="encryptPassword"
          />
        </div>

        <divider
          class="  my-4"
          border-dash
        />

        <div
          :class="['flex flex-col items-start gap-3', visibility.second? '':'opacity-50' ]"
        >
          <p class="text-darkPurple text-base">
            Change your organization’s Phone Number?
          </p>
          <p class="text-darkPurple text-sm">
            Please enter your organization’s new phone number.
          </p>
          <CText
            class="w-full"
            placeholder="New Phone Number"
            :disabled="!visibility.second"
            v-model="newPhoneNumber"
          />
          <Button
            :disabled="!visibility.second"
            :class="[' flex', visibility.second? 'text-flame':'text-jet' ]"
            @click="sendVerificationCode()"
          >
            <span class="mr-4">
              Send Verification Code
            </span>
          </Button>
        </div>

        <divider
          class="  my-4"
          border-dash
        />

        <div
          class="flex flex-col items-start gap-3"
          v-if="visibility.third"
        >
          <p class="text-darkPurple text-base">
            Verify Email
          </p>
          <p class="text-darkPurple text-sm">
            Enter the confirmation code send to
            specified email address to confirm ownership.
          </p>
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input mr-2 border-jet border"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="false"
            @on-complete="handleOnComplete"
          />
          <Button
            :disabled="!visibility.third"
            :class="[' flex', visibility.third ? 'text-flame':'text-jet' ]"
            @click="sendVerificationCode()"
          >
            <span class="text-flame">
              Resend Verification Code
            </span>
          </Button>

          <p>
            *Make sure you input the OTP correctly. Check your spam folder
            if you can’t find the verification email in your inbox.
            Otherwise, click on <span class="text-flame">“Resend Verification Code”
            </span>  above to get another cornfirmation code.
          </p>
        </div>
    </div>
  </RightSideBar>
</template>

<script>
import CText from '@scelloo/cloudenly-ui/src/components/text';
import OtpInput from "@bachdgvn/vue-otp-input";
import RightSideBar from '@/components/RightSideBar';
import Divider from '@/components/divider';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';

export default {
  components: {
    RightSideBar,
    Divider,
    CText,
    Checkbox,
    Button,
    "v-otp-input": OtpInput,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      newPhoneNumber: '',
      otpValue: '',
      passwordText: "password",
      encryptPassword: false,
      loading: false,
      visibility: {
        first: true,
        second: false,
        third: false,
      },
      error: {
        first: false,
        second: false,
        third: false,
      },
      selectedItems: [],
    };
  },
  watch: {
    encryptPassword() {
      if(this.passwordText === "password") {
        this.passwordText = "";
      }
      else {
        this.passwordText = "password";
      }
    }
  },
  methods: {
    checkPassword() {
      this.error.first = false;
      this.loading = true;
      this.$_checkPassword(
        {
          password: this.password,
          userId: this.$AuthUser.id,
        },
      ).then(() => {
        this.loading = false;
        this.visibility.first = false;
        this.visibility.second = true;
      }).catch(() => {
        this.loading = false;
        this.error.first = true;
      });
    },

    handleOnComplete(value) {
      const data = { otp: value, phoneNo: this.newPhoneNumber };
      this.saveNewPhone(data);
      this.$refs.otpInput.clearInput();
    },

    async saveNewPhone(phoneData) {
      this.loading = true;
      try {
        await this.$_updateOrgPhoneNumber({
          userId: this.$AuthUser.id,
          token: phoneData.otp,
          phoneNo: phoneData.phoneNo || null
        });
        this.$_getLinchpin().then(data => {
          const parsedLinchpin = data.data;
          localStorage.setItem("linchpin", JSON.stringify(parsedLinchpin));
          this.closeBar();
          this.$emit("save", phoneData);
          this.$toasted.success("Phone updated successfully", { duration: 5000 });
        })
      } catch (err) {
        this.loading = false;
        this.$toasted.error("Phone update failed...Invalid token", {
          duration: 5000
        });
        throw new Error(err);
      }
    },

    sendVerificationCode() {
      this.loading = true;
      this.$_sendOTP({
        userId: this.$AuthUser.id
      })
        .then(() => {
          this.loading = false;
          this.visibility.third = true;
          this.visibility.second = false;
        })
        .catch(() => {
          this.loading = false;
          this.error.second = true;
        });
    },

    closeBar() {
      this.$emit("close");
      this.visibility.first = true;
      this.visibility.second = false;
      this.visibility.third = false;
      this.error.first = false;
      this.error.second = false;
      this.error.third = false;
      this.password = "";
      this.newPhoneNumber = "";
      this.loading = false;
      this.encryptPassword = false;
    },
  },

};
</script>

<style >
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  text-align: center;
  outline: none;

}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
