<template>
  <div class="mx-3 mt-3">
    <div class="flex">
      <h1 class="text-xl flex-1 text-left font-bold flex mb-2">Profile</h1>
      <Button
        v-if="$AuthUser.isRoot"
        class="mb-4 text-white"
        :background="appearance.buttonColor || defaultBTNColor"
        @click="handleManageSub"
      >
        Manage Subscription
      </Button>
    </div>
    <div class="border mb-2" />
    <div>
      <Tab
        :tabs="tabs"
        border
        :active-tab="currentTab"
        @currentTab="currentTab = $event"
      />
      <Organisation v-if="currentTab === 'Organisation Details'" />
      <Admin v-if="currentTab === 'Root Admin'" />
      <AccountProfiles
        v-if="currentTab === 'Other Account Profile(s)'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Organisation from "./organisation";
import Admin from "./admin";
import AccountProfiles from "./accountProfiles";

export default {
  components: {
    Tab,
    Organisation,
    AccountProfiles,
    Admin,
    Button: () => import("@/components/Button"),
  },
  data() {
    return {
      showError: false,
      currentTab: "Root Admin",
    };
  },
  computed: {
    tabs() {
      if (
        process.env.NODE_ENV === "production" ||
        process.env.NODE_ENV === "staging"
      ) {
        return [
          'Root Admin',
          'Organisation Details',
        ]
      }
      return [
          'Root Admin',
          'Organisation Details',
          'Other Account Profile(s)',
        ]
    },
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    async displayProfile() {
      try {
        await this.$handlePrivilege("profile", "viewAccountProfileInfo");
        this.showError = false;
      } catch (error) {
        this.showError = true;
      }
    },
    handleManageSub() {
      return window.location.replace(
        `${process.env.VUE_APP_CLOUDENLY}subscriptions/management`
      );
    },
  },

  mounted() {
    this.displayProfile();
  },
};
</script>

<style></style>
