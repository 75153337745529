<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close')"
    :loading="loading"
    submit="Save"
    button-class="bg-dynamicBackBtn text-white rounded"
  >
    <template v-slot:title>
      <p class="text-darkPurple font-extrabold text-lg">
        Change Email
      </p>
    </template>
    <div>
      <scroll-container height="466px">
        <div
          :class="[
            'flex flex-col items-start gap-3',
            visibility.first ? '' : 'opacity-50'
          ]"
        >
          <p class="text-darkPurple text-base">
            Enter Password
          </p>
          <p class="text-darkPurple text-sm">
            Input account password to proceed.
          </p>
          <span class="text-red-500 mr-4" v-if="error.first"
            >Incorrect Password</span
          >
          <CText
            class="w-full"
            :variant="error.first ? 'error' : ''"
            placeholder="Password"
            :disabled="!visibility.first"
            v-model="password"
          />
          <Button
            @click="checkPassword"
            :disabled="!visibility.first"
            :class="[' flex', visibility.first ? 'text-flame' : 'text-jet']"
          >
            <span class="mr-24">
              Proceed
            </span>
          </Button>
        </div>

        <divider class="  my-4" border-dash />

        <div
          :class="[
            'flex flex-col items-start gap-3',
            visibility.second ? '' : 'opacity-50'
          ]"
        >
          <p class="text-darkPurple text-base">
            Change your organization’s email address?
          </p>
          <p class="text-darkPurple text-sm">
            Please enter your organization’s new email address.
          </p>
          <CText
            class="w-full"
            placeholder="Email"
            :disabled="!visibility.second"
            v-model="newEmail"
          />
          <Button
            :disabled="!visibility.second"
            :class="[' flex', visibility.second ? 'text-flame' : 'text-jet']"
            @click="sendVerificationToken()"
          >
            <span class="mr-4">
              Send Verification Code
            </span>
          </Button>
        </div>

        <divider class="  my-4" border-dash />

        <div class="flex flex-col items-start gap-3" v-if="visibility.third">
          <p class="text-darkPurple text-base">
            Verify Email
          </p>
          <p class="text-darkPurple text-sm">
            Enter the confirmation code send to specified email address to
            confirm ownership.
          </p>
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input mr-2 border-jet border"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="false"
            @on-complete="handleOnComplete"
          />
          <Button
            :disabled="!visibility.second"
            :class="[' flex', visibility.second ? 'text-flame' : 'text-jet']"
          >
            <span class="mr-4">
              Send Verification Code
            </span>
          </Button>

          <p>
            *Make sure you input the OTP correctly. Check your spam folder if
            you can’t find the verification email in your inbox. Otherwise,
            click on
            <span class="text-flame">“Resend Verification Code” </span> above to
            get another cornfirmation code.
          </p>
        </div>
      </scroll-container>
    </div>
  </RightSideBar>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import OtpInput from "@bachdgvn/vue-otp-input";
import RightSideBar from "@/components/RightSideBar";
import Divider from "@/components/divider";
import ScrollContainer from "@/components/ScrollContainer";
import Button from "@/components/Button";

export default {
  components: {
    RightSideBar,
    ScrollContainer,
    Divider,
    CText,
    Button,
    "v-otp-input": OtpInput
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newEmail: "",
      password: "",
      loading: false,
      visibility: {
        first: true,
        second: false,
        third: false
      },
      error: {
        first: false,
        second: false,
        third: false
      },
      selectedItems: [],
      users: [
        {
          id: "1",
          title: "Esther Klinsman",
          subTitle: "CHIEF OPERATIONS OFFICER ",
          designationRole: "• ADMIN",
          photo: null,
          selected: false
        },
        {
          title: "King Ladipo",
          id: "2",
          subTitle: "CHIEF FINANCIAL OFFICER ",
          designationRole: "• Guest",
          photo: null,
          selected: false
        }
      ]
    };
  },
  watch: {},
  methods: {
    checkPassword() {
      this.error.first = false;
      this.loading = true;
      this.$_checkPassword({
        password: this.password,
        userId: this.$AuthUser.id
      })
        .then(() => {
          this.loading = false;
          this.visibility.first = false;
          this.visibility.second = true;
        })
        .catch(() => {
          this.loading = false;
          this.error.first = true;
        });
    },
    sendVerificationToken() {},

    handleOnComplete(value) {
      this.$emit("save", value);
      this.$refs.otpInput.clearInput();
    }
  }
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  text-align: center;
  outline: none;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
