<template>
  <div>
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="mt-8" v-else>
      <div class="flex">
        <div class="flex flex-col flex-grow">
          <p
            class="text-lg flex-grow font-bold mb-2 capitalize text-darkPurple"
          >
            {{ organizationInfo.org.name }}
          </p>
          <span class="my-1 flex">
            <p class="text-base font-bold capitalize text-darkPurple">
              Domain Name :
            </p>
            <p class="py-1 px-3 -mt-3 ml-3 w-max rounded-md dom-label">
              <ValidationObserver rules="required">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  :disabled="!edit"
                  :errors="domainError"
                  @input="domainCheck($event)"
                  label="Domain Name"
                  v-model="organizationInfo.org.alias"
                />
              </ValidationObserver>
            </p>
          </span>
        </div>

        <span
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="handleClick" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
        </span>
      </div>
      <image-upload
        @file="setPhoto($event)"
        :url="url"
        :disabled="disabled"
        :photo="organizationInfo.org.logoUrl"
        :name="$OrgData.name"
      />
      <div class="grid grid-cols-12 gap-7 mt-6">
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Organisation Name"
            v-model="organizationInfo.org.name"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Type of Company"
            v-model="organizationInfo.org.type"
            :options="orgType"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            label="Incorporation No"
            v-model="organizationInfo.org.rcNumber"
            variant="w-full"
            :disabled="!edit"
          />
        </div>
        <div class="col-span-4">
          <div>
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              label="Email Address"
              disabled
              v-model="organizationInfo.org.email"
            />
          </div>
        </div>
        <div class="col-span-4">
          <div class="flex">
            <p class="flex flex-grow text-sm" style="margin-bottom: -1px">
              Phone Number
            </p>
            <p
              class="text-xs justify-end cursor-pointer"
              @click="ChangePhoneModal = true"
            >
              <icon
                icon-name="icon-edit"
                v-if="edit"
                size="xs"
                class="text-blueCrayola inline-block mr-2"
              />
            </p>
          </div>
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            disabled
            v-model="organizationInfo.org.phoneNo"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Website"
            v-model="organizationInfo.org.website"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Industry"
            :options="industries"
            v-model="organizationInfo.org.industryCategory"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Specializations"
            :options="getSubIndustries"
            v-model="selectedSubIndustry"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Business Coverage"
            v-model="organizationInfo.org.businessCoverage"
          />
        </div>
      </div>

      <div class="borderLine my-8" />
      <!----------------------------------------------
                    Contact Information
     ---------------------------------------------->
      <p class="font-semibold capitalize text-darkPurple">Contact Address</p>
      <div class="grid grid-cols-12 gap-7 mt-6">
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Address"
            v-model="organizationInfo.location.address"
          />
        </div>

        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Country"
            v-model="organizationInfo.location.country"
            :options="countries"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="City"
            v-model="organizationInfo.location.city"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="State"
            v-model="organizationInfo.location.stateId"
            v-if="organizationInfo.location.country === 'NGA'"
            :options="states"
          />
          <c-text
            placeholder="--Enter--"
            v-else
            label="State"
            :disabled="!edit"
            variant="w-full h-10"
            v-model="organizationInfo.location.state"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Time-Zone"
            v-model="organizationInfo.org.timeZone"
            :options="timezones"
          />
        </div>
        <div class="col-span-4">
          <c-select
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Language"
            v-model="organizationInfo.org.language"
            :options="languages"
          />
        </div>
      </div>
      <div class="borderLine my-8" />
      <!----------------------------------------------
                    Social information
     ---------------------------------------------->
      <p class="font-semibold capitalize text-darkPurple">Social Pages</p>
      <div class="grid grid-cols-12 gap-7 my-6">
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Facebook"
            v-model="organizationInfo.org.facebook"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Instagram"
            v-model="organizationInfo.org.instagram"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="LinkedIn"
            v-model="organizationInfo.org.linkedIn"
          />
        </div>
        <div class="col-span-4">
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            :disabled="!edit"
            label="Twitter"
            v-model="organizationInfo.org.twitter"
          />
        </div>
      </div>
      <div class="flex">
        <Button
          class="bg-dynamicBackBtn text-white my-6 w-40 flex"
          @click="handleSave()"
          v-if="edit"
          :disabled="disableBtn"
        >
          Save changes
        </Button>
        <Button @click="edit = false" v-if="edit" class="buttons mt-6">
          Cancel
        </Button>
      </div>
    </div>
    <ChangeEmail
      :open-modal="ChnageEmailModal"
      @close="ChnageEmailModal = false"
      v-if="enableEdit"
    />
    <ChangePhone
      :open-modal="ChangePhoneModal"
      @save="editNewPhone($event)"
      @close="ChangePhoneModal = false"
      v-if="enableEdit"
    />
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import { mapState, mapActions } from "vuex";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import imageUpload from "@/components/imageUpload";
import countries from "@/helpers/countries";
import states from "@/helpers/states";
import timezones from "@/helpers/timezone";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import ChangeEmail from "../sidebar/ChangeEmail";
import ChangePhone from "../sidebar/ChangePhone";

export default {
  components: {
    ValidationObserver,
    imageUpload,
    CText,
    Button,
    CSelect,
    ChangeEmail,
    ChangePhone,
    Icon,
  },
  name: "Organisation",
  data() {
    return {
      disableBtn: false,
      edit: false,
      countries,
      states,
      timezones,
      loading: true,
      enableEdit: false,
      url: process.env.VUE_APP_IMAGE_UPLOAD,
      photo: "",
      response: "",
      responseBg: "",
      success: false,
      ChnageEmailModal: false,
      ChangePhoneModal: false,
      languages: ["English"],
      selectedSubIndustry: "",
      disabled: false,
      organizationInfo: {
        org: {
          id: "id",
          logoUrl: null,
          name: "",
          alias: "",
          type: "",
          rcNumber: "",
          email: "",
          dialCode: "234",
          phoneNo: "",
          website: "",
          industryCategory: "5",
          businessCoverage: "",
          timeZone: null,
          language: null,
          facebook: "",
          twitter: "",
          instagram: "",
          linkedIn: "",
        },
        location: {
          id: "",
          address: "",
          city: "",
          state: "",
          stateId: null,
          country: "",
          latitude: null,
          longitude: null,
        },
        specializations: [],
      },
      orgType: [],
      domainError: [],
      domainExist: false,
    };
  },
  watch: {
    selectedSubIndustry() {
      if (this.selectedSubIndustry) {
        this.addSpecialization(this.selectedSubIndustry);
      }
    },
  },
  computed: {
    ...mapState({
      industries: (state) => state.industries,
    }),
    getSubIndustries() {
      const result = this.industries.find(
        (item) => item.id === this.organizationInfo.org.industryCategory
      );
      if (result) {
        return result.subIndustries;
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      updateProfile: "org/updateProfile",
      sendTwoFactorCode: "org/sendTwoFactorCode",
    }),
    async handleClick() {
      try {
        await this.$handlePrivilege("profile", "editAccountProfileInformation");
        this.edit = !this.edit;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async permitEdit() {
      try {
        await this.$handlePrivilege("profile", "editAccountProfileInformation");
        this.enableEdit = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handlePromise() {
      try {
        await this.$handlePrivilege("profile", "editAccountProfileInformation");
        this.disabled = false;
      } catch (error) {
        this.disabled = true;
      }
    },
    setPhoto(value) {
      this.photo = value;
      this.organizationInfo.org.logoUrl = value;
    },
    handleManageSub() {
      return window.location.replace(
        `${process.env.VUE_APP_CLOUDENLY}subscriptions/management`
      );
    },

    domainCheck(value) {
      const lowerCaseDomain = value.toLowerCase();
      const formatDomain = lowerCaseDomain.replaceAll(" ", "-");
      this.$_checkDomain(formatDomain).then((response) => {
        this.domainExist = response.data.aliasExists;
        if (this.domainExist) {
          this.domainError.push(`Domain name already in use`);
        } else {
          this.domainError = [];
        }
      });
    },

    handleSave() {
      if (this.domainError.length === 0) {
        this.disableBtn = true;
        if (this.organizationInfo.location.stateId) {
          const selectedState = this.states.find(
            (state) => state.id === this.organizationInfo.location.stateId
          );
          this.organizationInfo.location.state = selectedState?.name;
        }
        const promise = this.updateProfile(this.organizationInfo);

        promise.then((result) => {
          this.$toasted.success(result.message, { duration: 5000 });
          this.response = result.message;
          this.responseBg = "bg-mediumSeaGreen";
          this.edit = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.disableBtn = false;
        });

        this.profileEditable = false;
        this.contactEditable = false;
        this.additionalEditable = false;
      }
      else {
          window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    addSpecialization(id) {
      const subIndustry = this.getSubIndustries.find((item) => item.id === id);
      this.organizationInfo.specializations = [
        {
          orgId: this.$orgId,
          subIndustryId: subIndustry.id,
        },
      ];
    },
    async saveNewEmail(email) {
      const oldEmail = this.organizationInfo.org.email;
      try {
        this.organizationInfo.org.email = email;
        this.$store.commit("SET_TOAST", {
          text: "Email updated successfully",
          color: "#009432",
        });
        this.$_updateOrgEmail({
          userId: this.rootUser.id,
          token: this.modals.email.token,
          email,
        });
        this.profileEditable = false;
      } catch (err) {
        this.$store.commit("SET_TOAST", {
          text: "Email update failed reverting back",
          color: "#EA2027",
        });
        this.organizationInfo.org.email = oldEmail;
        throw new Error(err);
      }
      this.profileEditable = false;
    },

    async editNewPhone(phoneData) {
      this.organizationInfo.org.phoneNo = phoneData.phoneNo;
    },

    initTwoFactorCode(email, type) {
      this.sendTwoFactorCode({ userId: this.rootUser.id, email });
      if (type === "phone") {
        this.modals.phone.otpModal = true;
      } else {
        this.modals.email.otpModal = true;
      }
    },
    initNewEmail(token, type) {
      if (type === "phone") {
        this.modals.phone.newModal = true;
        this.modals.phone.token = token;
      } else {
        this.modals.email.newModal = true;
        this.modals.email.token = token;
      }
    },
    mountOrgLogo() {
      this.$_getOrgSettings().then((result) => {
        this.organizationInfo.org.logoUrl = result.data.org.logoUrl;
        const selectedSubIndustry = result.data.org.specializations.map(
          (v) => ({
            id: v.id,
            name: v.name,
          })
        );
        this.selectedSubIndustry = selectedSubIndustry[0].id;
      });
      this.orgType = [
        { id: 1, name: "Sole Proprietorship" },
        { id: 2, name: "Private Limited Company (LTD)" },
        { id: 3, name: "Public Limited Company (PLC)" },
        { id: 4, name: "Limited by Gurantee" },
        { id: 5, name: "Unlimited Company" },
      ];
    },
  },
  async mounted() {
    await this.$store.dispatch("getIndustries");
    await this.$store.dispatch("location/getDefaultLocation");
    this.organizationInfo.org = this.$store.getters["org/orgProfile"];
    this.organizationInfo.specializations =
      this.$store.getters["org/orgSpecializations"];
    this.organizationInfo.location =
      await this.$store.getters["location/defaultLocation"];
    this.loading = false;
    await this.permitEdit();
    await this.mountOrgLogo();
    await this.handlePromise();
  },
};
</script>

<style>
.dom-label label {
  display: none;
}
</style>
<style scoped>
.dom-label label {
  display: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
</style>
