var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.openModal)?_c('RightSideBar',{attrs:{"loading":_vm.loading,"submit":"Save","button-class":"bg-dynamicBackBtn text-white rounded"},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"text-darkPurple font-extrabold text-lg"},[_vm._v(" Change Email ")])]},proxy:true}],null,false,1048007022)},[_c('div',[_c('scroll-container',{attrs:{"height":"466px"}},[_c('div',{class:[
          'flex flex-col items-start gap-3',
          _vm.visibility.first ? '' : 'opacity-50'
        ]},[_c('p',{staticClass:"text-darkPurple text-base"},[_vm._v(" Enter Password ")]),_c('p',{staticClass:"text-darkPurple text-sm"},[_vm._v(" Input account password to proceed. ")]),(_vm.error.first)?_c('span',{staticClass:"text-red-500 mr-4"},[_vm._v("Incorrect Password")]):_vm._e(),_c('CText',{staticClass:"w-full",attrs:{"variant":_vm.error.first ? 'error' : '',"placeholder":"Password","disabled":!_vm.visibility.first},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('Button',{class:[' flex', _vm.visibility.first ? 'text-flame' : 'text-jet'],attrs:{"disabled":!_vm.visibility.first},on:{"click":_vm.checkPassword}},[_c('span',{staticClass:"mr-24"},[_vm._v(" Proceed ")])])],1),_c('divider',{staticClass:"my-4",attrs:{"border-dash":""}}),_c('div',{class:[
          'flex flex-col items-start gap-3',
          _vm.visibility.second ? '' : 'opacity-50'
        ]},[_c('p',{staticClass:"text-darkPurple text-base"},[_vm._v(" Change your organization’s email address? ")]),_c('p',{staticClass:"text-darkPurple text-sm"},[_vm._v(" Please enter your organization’s new email address. ")]),_c('CText',{staticClass:"w-full",attrs:{"placeholder":"Email","disabled":!_vm.visibility.second},model:{value:(_vm.newEmail),callback:function ($$v) {_vm.newEmail=$$v},expression:"newEmail"}}),_c('Button',{class:[' flex', _vm.visibility.second ? 'text-flame' : 'text-jet'],attrs:{"disabled":!_vm.visibility.second},on:{"click":function($event){return _vm.sendVerificationToken()}}},[_c('span',{staticClass:"mr-4"},[_vm._v(" Send Verification Code ")])])],1),_c('divider',{staticClass:"my-4",attrs:{"border-dash":""}}),(_vm.visibility.third)?_c('div',{staticClass:"flex flex-col items-start gap-3"},[_c('p',{staticClass:"text-darkPurple text-base"},[_vm._v(" Verify Email ")]),_c('p',{staticClass:"text-darkPurple text-sm"},[_vm._v(" Enter the confirmation code send to specified email address to confirm ownership. ")]),_c('v-otp-input',{ref:"otpInput",attrs:{"input-classes":"otp-input mr-2 border-jet border","separator":"","num-inputs":6,"should-auto-focus":true,"is-input-num":false},on:{"on-complete":_vm.handleOnComplete}}),_c('Button',{class:[' flex', _vm.visibility.second ? 'text-flame' : 'text-jet'],attrs:{"disabled":!_vm.visibility.second}},[_c('span',{staticClass:"mr-4"},[_vm._v(" Send Verification Code ")])]),_c('p',[_vm._v(" *Make sure you input the OTP correctly. Check your spam folder if you can’t find the verification email in your inbox. Otherwise, click on "),_c('span',{staticClass:"text-flame"},[_vm._v("“Resend Verification Code” ")]),_vm._v(" above to get another cornfirmation code. ")])],1):_vm._e()],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }