<template>
    <div class="flex flex-col w-full my-6">
        <div class="flex py-3 px-2 border-b-2">
            <div class="flex flex-col justify-center flex-grow">
                <div class="flex">
                <p class="flex gap-3 text-sm font-semibold">
                    Enable Group Structure?
                </p>
                <span class="flex gap-3 mx-4">
                    <switch-toggle v-model="groupOrg" />
                </span>
                </div>
            </div>
            <div class="flex justify-between">
                <Card class="p-2">
                    <p class="text-base text-flame">Merge Billing & Subscription</p>
                </Card>

          <Button
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
            width="8rem"
          >
            Sync Account
          </Button>

          <Button
            class="bg-dynamicBackBtn text-white ml-4"
            width="8rem"
            style="height: 2.75rem"
          >
            Add new
          </Button>

                </div>
        </div>

        <div class="my-4">
        <Table
          :headers="orgHeader"
          :items="accountsData"
          aria-label="advance Table"
          class="w-full my-3"
          :has-checkbox="true"
          has-number
          v-if="accountsData.length > 0"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.name">
              {{ item.data.name }}
            </div>
            <div v-else-if="item.email">
              {{ item.data.email }}
            </div>
            <div v-else-if="item.country">
              {{ item.data.country }}
            </div>
            <div v-else-if="item.domain">
              {{ item.data.domain }}
            </div>
            <div v-else-if="item.synced" class="mr-4 text-center">
              {{item.data.synced}}
            </div>
            <div v-else-if="item.status" class="mr-4 text-center">
                <Badge
                  label="Active"
                  variant="primary"
                  background-color="rgba(19, 181, 106, 0.08)"
                  color="#13B56A"
                  style="padding: 8px; height: 28px; font-weight: 700"
                />
            </div>
            <div v-else-if="item.id">
              <Menu left top="-150" margin="24" class="my-2 p-0">
                <template v-slot:title>
                  <icon icon-name="more_icon" size="xs" />
                </template>
                  <div class="w-48 flex flex-col justify-start items-center gap-4 px-3 py-3">
                    <div class="w-full">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer" @click="editModal(item.data)">
                        <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Edit</p>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer" @click="switchAccount(item.data)">
                                          <Icon
                    icon-name="icon-switch"
                    class="text-flame"
                    size="xsm"
                  />
                        <p class="font-normal text-sm text-darkPurple">Switch to account</p>
                      </div>
                    </div>
                    <div class="w-full">
                      <div class="w-full flex justify-start items-center gap-2 cursor-pointer">
                        <Icon icon-name="add-account" size="xs" />
                        <p class="font-normal text-sm text-darkPurple">Add new accounts</p>
                      </div>
                    </div>
                      <div class="w-full">
                        <div class="w-full flex justify-start items-center gap-2 cursor-pointer" @click="deactivateOrgModal(item.data)">
                          <Icon icon-name="delete" class-name="text-flame" size="xs" />
                          <p class="font-normal text-sm text-darkPurple">Remove</p>
                        </div>
                      </div>
                  </div>
                </Menu>
            </div>
            </template>
            </Table>
            </div>

  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="groupOrg"
      submit="Save"
      @submit="handleSubmit(groupOrganize)"
      @close="groupOrg = false"
       button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
         Group Structure
        </p>
      </template>
      <div class="my-5 flex p-3 gap-4 rounded-sm" style="background-color: rgba(233, 147, 35, 0.08); border: 0.6px solid #E99323">
        <p class="text-sm">
          The group feature allows you to manage different
          Cloudenly accounts and streamline your reporting as you would for group operations.
          Notwithstanding, each account in the group still functions independently.
        </p>
      </div>
      <div class="my-3 flex flex-col">
        <p class="text-base my-1">Please provide details of your group structure.</p>
        <div class="flex flex-col my-4 gap-6">
        <CSelect
          :options="holdingAccounts"
          label="Holding Company"
          class="w-full"
          v-model="holdingAcct"
          :rules="['required']"
        />
        <div class="w-full flex flex-col">
          <label class="mb-1">Operating Companies</label>
                <v-select
                  :clearable="false"
                  class="style-select"
                  label="name"
                  multiple
                  name="location"
                  :reduce="option => option.id"
                  :options="operatingAccounts"
                  v-model="operating"
                  :close-on-select="true"
                  placeholder="Operating Accounts"
                  :rules="['required']"
                />
                </div>
        </div>
      </div>
    </RightSideBar>
    </ValidationObserver>

  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
   <RightSideBar
   v-if="editToggle"
    @close="editToggle=false"
    @submit="handleSubmit(getOTP)"
    submit="Save"
    button-class="bg-dynamicBackBtn text-white"
    width="width:520px"
    >
    <template v-slot:title>
      <p class="text-darkPurple">Edit Account Profile</p>
    </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple pb-4">
          You can update this account details if you have the right privilege(s).
        </p>
        </template>
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-12">
                <c-text
                  placeholder="--Enter--"
                  label="Name"
                  variant="w-full h-10"
                  v-model="editInfo.name"
              :rules="['required']"
                />
              </div>

              <div class="col-span-12">
                <c-text
                  placeholder="--Enter--"
                  label="Domain"
                  variant="w-full h-10"
                  v-model="editInfo.domain"
              :rules="['required']"
                />
              </div>

              <div class="col-span-12">
                <c-text
                  placeholder="--Enter--"
                  label="Email"
                  variant="w-full h-10"
                  v-model="editInfo.email"
              :rules="['required']"
                />
              </div>

              <div class="col-span-12">
                <c-text
                  placeholder="--Enter--"
                  label="Phone Number"
                  variant="w-full h-10"
                  v-model="editInfo.phone"
              :rules="['required']"
                />
              </div>

              <div class="col-span-12">
            <c-select
              placeholder="Select Country"
              variant="w-full h-10 bg-white"
              class="label-input"
              label="Country"
              :options="countryOptions"
              v-model="editInfo.country"
              :rules="['required']"
            />
              </div>
        </div>
  </RightSideBar>
  </ValidationObserver>




     <RightSideBar
     v-if="otpModal"
    @close="otpModal=false"
    close-button
    width="width:520px"
  >
    <template v-slot:title>
      <p class="text-darkPurple">Account Update Verification</p>
    </template>

      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple pb-4">
          To ensure that this is you, we have sent you a one time verification code to your registered email {{ editInfo.email }}.
          Enter the code below to confirm this action. This code will expire in 5 minutes.
        </p>
        </template>

    <div class="w-full flex flex-col justify-center p-4 bg-ghostWhite">
      <p class="text-xl font-bold py-2">Enter your verification code</p>
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input mr-2 border-jet border my-4"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="false"
              @on-complete="handleOnComplete"
            />
    </div>
  </RightSideBar>




   <RightSideBar
   v-if="deactivateToggle"
    @close="deactivateToggle=false"
    @submit="deactivateOrg"
    submit="Confirm"
    button-class="bg-dynamicBackBtn text-white"
    width="width:520px"
  >
    <template v-slot:title>
      <p class="text-darkPurple">Remove Profile</p>
    </template>

    <div class="w-full flex flex-col justify-center" style="align-items: center; height: 60vh">
      <img src="@/assets/images/Draw.png" width="150" />
      <div class="w-4/5" style="text-align: center">
        <p class="my-3 font-bold text-2xl capitalize">
          Confirm you want to remove this profile
        </p>
        <p class="my-3 text-base">
          When you remove a profile you can always reactivate at anytime you wish.
        </p>
      </div>
    </div>
  </RightSideBar>


    </div>
</template>


<script>
import { ValidationObserver } from "vee-validate";
import VSelect from "vue-select";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import OtpInput from "@bachdgvn/vue-otp-input";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import "vue-select/dist/vue-select.css"
import SwitchToggle from "@/components/Switch";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import RightSideBar from "@/components/RightSideBar";
import countries from "@/helpers/countries";

export default {
    name: "Active",
    components: {
      SwitchToggle,
      ValidationObserver,
      VSelect,
        CSelect,
    "v-otp-input": OtpInput,
      Badge,
        RightSideBar,
        Card,
        Button,
        Menu,
        Table
    },
    data() {
        return {
            orgHeader: [
        { title: "Account Name", value: "name" },
        { title: "Country", value: "country" },
                { title: "Domain Name", value: "domain" },
                { title: "Email", value: "email" },
                { title: "Created/Synced", value: "synced" },
                { title: "Status", value: "status" },
        { title: "", value: "id", image: true }
      ],
            accountsData: [],
          groupOrg: false,
          holdingAccounts: [],
          operatingAccounts: [],
            holdingAcct: '',
          deactivateToggle: false,
          organisationId: '',
          organisationUserId: '',
          editToggle: false,
          editInfo: {},
          countryOptions: countries,
          otpModal: false,
          holding: [],
          operating: []
        }
    },
    props: {
        orgAccounts: {
            type: Array,
            default: () => []
      },
      syncId: {
        type: String,
        default: ""
      }
  },

  watch: {
    holdingAcct(data) {
      this.holding = [data];
      const index = this.holdingAccounts.findIndex((account) => account.id === data);

      this.orgAccounts.forEach((account) => {
        this.operatingAccounts.push({
          id: account.organisation.id,
          name: account.organisation.name,
        })
      });

      this.operatingAccounts.splice(index, 1);
    }
  },

  methods: {
    groupOrganize() {
      const payload = {
        accountSyncId: this.syncId,
        operating: this.operating,
        holding: this.holding
      };

      this.$_groupMultiOrg(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
      }).catch((err) => {
        this.$toasted.error(err.response.data.message, {duration: 5000})
      })
    },

    deactivateOrgModal(data) {
      this.deactivateToggle = true;
      this.organisationId = data.id;
      this.organisationUserId = data.userId;
    },

    editModal(data) {
      this.editToggle = true;
      this.editInfo = data;
    },

    deactivateOrg() {
      const payload = {
        userId: this.organisationUserId,
        orgId: this.organisationId
      };
      this.$_deactivateOrganization(payload).then((response) => {
        this.deactivateToggle = false;
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.$emit('change', true)
      }).catch((err) => {
        this.$toasted.error(err.response.data.message, { duration: 5000 });
      })
    },

    getOTP() {
      const payload = {
        userId: this.$AuthUser.id,
        email: this.editInfo.email
      };
      this.$_verifyOrgOTP(payload).then(() => {
        this.otpModal = true;
        this.editToggle = false;
      }).catch((err) => {
        this.$toasted.error(err, { duration: 5000 });
      })
    },

    handleOnComplete(value) {
      const payload = {
        userId: this.$AuthUser.id,
        emailVerificationCode: value,
        name: this.editInfo.name,
        domain: this.editInfo.domain,
        email: this.editInfo.email,
        phone: this.editInfo.phone,
        orgId: this.editIndo.id
      };
      this.$_updateMultiOrg(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.otpModal = false;
        this.$emit('change', true)
      }).catch((err) => {
        this.$toasted.error(err.response.data.message, { duration: 5000 });
      })
    },

     switchAccount(data) {
      const payload = {
        userId: data.userId,
        sync: data.userId === this.$AuthUser.id
      };

      this.$_switchOrganisation(data.id, payload).then((response) => {
        const jwtToken = response.data.token;
        const linchpin = response.data;

        window.localStorage.setItem("linchpin", JSON.stringify(linchpin));

        window.localStorage.setItem("token", jwtToken);

        window.location.assign("/admin/dashboard");
      });
    },


    },

    created() {
        this.orgAccounts.forEach((account) => {
          if (account.subscriptionStat === 'active') {
            this.accountsData.push({
              name: account.organisation.name,
              userId: account.userId,
              id: account.organisation.id,
              email: account.organisation.email,
              country: account.country,
              domain: account.organisation.alias,
              synced: account.organisation.syncedDate ? this.$DATEFORMAT(new Date(account.organisation.syncedDate), "MMMM dd, yyyy") : '---',
              status: account.subscriptionStat
            })
          }
          this.holdingAccounts.push({
            id: account.organisation.id,
            name: account.organisation.name,
          });
        })
    }
}
</script>