<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close')"
    :loading="loading"
    submit="Save"
    button-class="bg-dynamicBackBtn text-white rounded"
  >
    <template v-slot:title>
      <p class="text-darkPurple font-extrabold text-lg">
        Security Question
      </p>
    </template>
    <div>
      <scroll-container
        height="466px"
      >
        <div :class="['flex flex-col items-start gap-3', visibility.first? '':'opacity-50' ]">
          <p class="text-darkPurple text-base">
            Set an alternate way to recover your account
          </p>
          <p class="text-darkPurple text-sm">
            Start by entering your password below
          </p>
          <span
            class="text-red-500 mr-4"
            v-if="error.first"
          >Incorrect Password</span>
          <CText
            class="w-full"
            :variant="error.first?'error':''"
            placeholder="Current Password"
            :disabled="!visibility.first"
            v-model="password"
          />
          <Button
            @click="checkPassword"
            :disabled="!visibility.first"
            :class="[' flex', visibility.first? 'text-flame':'text-jet' ]"
          >
            <span class="mr-24">
              Continue
            </span>
          </Button>
        </div>

        <divider
          class="  my-4"
          border-dash
        />

        <div
          :class="['flex flex-col items-start gap-3', visibility.second? '':'opacity-50' ]"
        >
          <p class="text-darkPurple text-base">
            Select Security Question
          </p>
          <p class="text-darkPurple text-sm">
            Select a security question and input an answer
          </p>
          <CSelect
            class="w-full"
            variant="w-full"
            placeholder="--Select Question--"
            :disabled="!visibility.second"
          />
          <CText
            class="w-full"
            placeholder="Enter Answer"
            :disabled="!visibility.second"
          />
        </div>
      </scroll-container>
    </div>
  </RightSideBar>
</template>

<script>
import CSelect from '@scelloo/cloudenly-ui/src/components/select';
import CText from '@scelloo/cloudenly-ui/src/components/text';
import RightSideBar from '@/components/RightSideBar';
import Divider from '@/components/divider';
import ScrollContainer from '@/components/ScrollContainer';
import Button from '@/components/Button';

export default {
  components: {
    RightSideBar,
    ScrollContainer,
    Divider,
    CText,
    CSelect,
    Button,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmPassword: '',
      newPassword: '',
      password: '',
      loading: false,
      visibility: {
        first: true,
        second: false,
        third: false,
      },
      error: {
        first: false,
        second: false,
        third: false,
      },
      selectedItems: [],
      users: [
        {
          id: '1',
          title: 'Esther Klinsman',
          subTitle: 'CHIEF OPERATIONS OFFICER ',
          designationRole: '• ADMIN',
          photo: null,
          selected: false,
        },
        {
          title: 'King Ladipo',
          id: '2',
          subTitle: 'CHIEF FINANCIAL OFFICER ',
          designationRole: '• Guest',
          photo: null,
          selected: false,
        },
      ],
    };
  },
  computed: {
    confirmBothPassword() {
      if (this.newPassword !== '' && this.confirmPassword !== '') {
        if (this.newPassword === this.confirmPassword) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  watch: {
    confirmPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.error.second = true;
      } else {
        this.error.second = false;
      }
    },

  },
  methods: {
    checkPassword() {
      this.error.first = false;
      this.loading = true;
      this.$_checkPassword(
        {
          password: this.password,
          userId: this.$AuthUser.id,
        },
      ).then(() => {
        this.loading = false;
        this.visibility.first = false;
        this.visibility.second = true;
      }).catch(() => {
        this.loading = false;
        this.error.first = true;
      });
    },

    handleOnComplete(value) {
      this.$emit("save", value);
      this.$refs.otpInput.clearInput();
    },
  },

};
</script>

<style >
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  text-align: center;
  outline: none;

}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
