<template>
    <div class="w-full h-full flex flex-col my-2 p-3">
        <div class="flex gap-2 self-stretch">
            <span class="text-sm text-jet">
                Account switching enables you to synchronize and switch multiple Cloudenly accounts.
                You can manage each account independently or set up a group structure.
                <span class="text-blueCrayola cursor-pointer" @click="moreContent = true">Learn more</span>
            </span>
        </div>

    <div class="my-7">
      <Tab
        :tabs="['Active', 'Inactive']"
        border
        :active-tab="currentTab"
        @currentTab="currentTab = $event"
      />

        <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <Active v-if="currentTab === 'Active' && activeOrgList.length > 0" :org-accounts="activeOrgList" :sync-id="accountSyncId" @change="getAllOrganisations()" />
      <InActive v-if="currentTab === 'Inactive' && inActiveOrgList.length > 0" :org-accounts="inActiveOrgList" @change="getAllOrganisations()" />
    </div>
    </div>

              <Modal v-if="moreContent">
                <Card class="flex flex-col max-w-md">
                  <Icon
                  icon-name="closeModal"
                  class="absolute cursor-pointer"
                  style="left: 63.2vw"
                  size="xsm"
                  @click.native="moreContent = false"
                  />
                  <div class="flex flex-col p-5">
                  <div class="h-32 bg-ghostWhite flex flex-col justify-center w-full rounded-sm">
                    <div class="flex px-3 w-full">
                      <div class="flex flex-col justify-center">
                      <p class="text-2xl mt-3 font-bold">
                        Multi-account operation
                      </p>
                      </div>
                      <img src="@/assets/images/rocket_sign.png" style="width: 132px" />
                    </div>
                  </div>
                  <div class="flex flex-col w-full my-3 text-sm">
                    <p>
                      Payroll operations and compliance are country- or jurisdiction-specific.
                      Therefore you should create a new account for each country if you run operations across different countries.
                      The Cloudenly payroll currently conforms to the Nigerian payroll regulations only.
                      </p>
                      <p class="my-2">
                        We will update you as we activate other jurisdictions.
                        If you run different operations within the same payroll jurisdiction, you can manage all your operations on the same account
                         if they share accounting and reporting; otherwise, you can set up different Cloudenly accounts.
                      </p>
                      <p>
                        The group feature allows you to manage different Cloudenly
                        accounts and streamline your reporting as you would for group operations.
                        Notwithstanding, each account in the group still functions independently.
                    </p>
                  </div>
                  </div>
                </Card>
              </Modal>

    </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Modal from "@/components/Modal";
import Card from "@/components/Card";
import Active from "./active";
import InActive from "./inActive";

export default {
    name: 'AccountProfiles',
    components: {
        Tab,
      Card,
        InActive,
      Modal,
        Active
    },
  data() {
    return {
        currentTab: "Active",
      moreContent: false,
      activeOrgList: [],
      inActiveOrgList: [],
        loading: true,
        accountSyncId: ""
    };
  },

  methods: {
    getAllOrganisations() {
      this.getAllActiveOrganisations();
      this.getAllInactiveOrganisations();
    },

    getAllActiveOrganisations() {
      this.$_getActiveMultiOrganisation(this.$AuthUser.id, 'active').then((response) => {
        this.accountSyncId = response.data.accountsyncId;
        this.activeOrgList = response.data.organisation;
        this.loading = false;
      });
    },

    getAllInactiveOrganisations() {
      this.$_getActiveMultiOrganisation(this.$AuthUser.id, 'inactive').then((response) => {
        this.inActiveOrgList = response.data.organisation;
        this.loading = false;
      });
    },

  },

  mounted() {
    this.getAllActiveOrganisations()
    this.getAllInactiveOrganisations()
  }
}
</script>