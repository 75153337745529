<template>
  <RightSideBar
    v-if="openModal"
    @close="closeModal"
    :loading="loading"
    @submit="transferRight"
    submit="Save"
    button-class="bg-dynamicBackBtn text-white rounded"
    :disabled-button="disableBtn"
  >
    <template v-slot:title>
      <p class="text-darkPurple font-extrabold text-lg">
        Transfer Admin Rights
      </p>
    </template>
    <div>
        <div :class="['flex flex-col items-start gap-3', visibility.first? '':'opacity-50' ]">
          <p class="text-red-500 text-base">
            You are about to transfer your admin status to another user!
          </p>
          <p class="text-darkPurple text-sm">
            Start by entering your password below
          </p>
          <span
            class="text-red-500 mr-4"
            v-if="error.first"
          >Incorrect Password</span>
          <CText
            class="w-full"
            :variant="error.first?'error':''"
            placeholder="Current Password"
            :disabled="!visibility.first"
            :type="passwordText"
            v-model="password"
          />
          <checkbox
            label="Show Password"
            checkbox-size="width:16px; height: 16px; margin-top: 3%"
            class="flex justify-center items-center"
            v-model="encryptPassword"
          />
          <Button
            @click="checkPassword"
            :disabled="!visibility.first"
            :class="[' flex', visibility.first? 'text-flame':'text-jet' ]"
          >
            <span class="mr-24">
              Continue
            </span>
          </Button>
        </div>

        <divider
          class="  my-4"
          border-dash
        />

        <div style="height:100%" class="mt-8 mb-8" v-if="resendLoader">
          <loader size="xxs" :loader-image="false" />
        </div>

        <div v-else>

        <div
          :class="[
            'flex flex-col items-start gap-3',
            visibility.second ? '' : 'opacity-50'
          ]"
        >

          <p class="text-darkPurple text-sm mb-1">
            Enter the confirmation code send to specified email address to
            confirm ownership.
          </p>
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input mr-2 mb-1 border-jet border"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="false"
            @on-complete="handleOnComplete"
          />
          <Button
            @click="resendCode"
            :disabled="!visibility.second"
            :class="['w-full flex mb-1', visibility.second ? 'text-flame' : 'text-jet']"
            width="15rem"
          >
            <span class="mr-4 w-full text-flame">
              Resend Verification Code
            </span>
          </Button>

          <p>
            *Make sure you input the OTP correctly. Check your spam folder if
            you can’t find the verification email in your inbox. Otherwise,
            click on
            <span class="text-flame">“Resend Verification Code” </span> above to
            get another cornfirmation code.
          </p>
        </div>
        </div>

        <divider
          class="  my-4"
          border-dash
        />

        <div
          :class="['flex flex-col items-start gap-3', visibility.third? '':'opacity-50' ]"
        >
          <p class="text-darkPurple text-base">
            Select User
          </p>
          <p class="text-darkPurple text-sm">
            Search for user to transfer Admin rights to.
          </p>
          <SearchInput
            placeholder="Search"
            class="w-full"
          />
          <div
            class="w-full "
            v-for="(user, index) in users"
            :key="index"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center">
              <img
                v-if="user.photo != null && user.photo != ''"
                class="mr-2 chat-img-x"
                style="width: 30px; height: 30px"
                :src="user.photo"
              />
              <div
                style="height: 30px; width: 30px; border-radius: 5px;"
                class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                v-else
              >
                {{ $getInitials(`${user.fname} ${user.lname}`) }}
              </div>
                <p class="flex flex-col">
                  <span class="text-darkPurple text-base">{{`${user.fname} ${user.lname}`}}</span>
                  <span class="uppercase text-romanSilver text-xs">{{user.designation}}</span>
                </p>
              </div>

              <checkbox
                label=""
                checkbox-size="height:16px; width:16px;"
                label-style="color:#333333; "
                :value="user.id"
                v-model="employeeCanUpload"
              />
            </div>
            <divider
              class="mt-4"
              border-dash
            />
          </div>
        </div>
    </div>
  </RightSideBar>
</template>

<script>
import CText from '@scelloo/cloudenly-ui/src/components/text';
import OtpInput from "@bachdgvn/vue-otp-input";
import SearchInput from '@scelloo/cloudenly-ui/src/components/search-input';
import RightSideBar from '@/components/RightSideBar';
import Divider from '@/components/divider';
import Button from '@/components/Button';
import Checkbox from '@/components/Checkbox';

export default {
  components: {
    RightSideBar,
    Divider,
    "v-otp-input": OtpInput,
    CText,
    Button,
    Checkbox,
    SearchInput,
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableBtn: false,
      confirmPassword: '',
      resendLoader: false,
      newPassword: '',
      password: '',
      passwordText: "password",
      encryptPassword: false,
      loading: false,
      visibility: {
        first: true,
        second: false,
        third: false,
      },
      error: {
        first: false,
        second: false,
        third: false,
      },
      selectedItems: [],
      users: [],
      employeeCanUpload: [],
    };
  },
  computed: {
    confirmBothPassword() {
      if (this.newPassword !== '' && this.confirmPassword !== '') {
        if (this.newPassword === this.confirmPassword) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  watch: {
    confirmPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.error.second = true;
      } else {
        this.error.second = false;
      }
    },
    encryptPassword() {
      if(this.passwordText === "password") {
        this.passwordText = "";
      }
      else {
        this.passwordText = "password";
      }
    }
  },
  methods: {
    transferRight() {
      this.disableBtn = true;
      if(this.employeeCanUpload.length > 1) {
        this.$toasted.error("You cannot select more than 1 user", { duration: 5000 });
      }
      else {
        const payload = {
          currentRootAdminId: this.$AuthUser.id,
          newRootAdminId: this.employeeCanUpload.toString(),
          otp: this.otpInput
        };
        this.$_transferAdminRights(payload).then(() => {
          this.$toasted.success("Admin rights transferred successfully", { duration: 3000 });
          this.disableBtn = false;
          this.closeModal();
          this.$handleLogout();
        }).catch(() => { this.$toasted.error("Error in transfering admin rights", { duration: 3000 });
        this.disableBtn = false;
        });
      }
    },

    handleOnComplete(value) {
      this.otpInput = value;
      this.visibility.second = false;
      this.visibility.third = true;
    },

    checkPassword() {
      this.error.first = false;
      this.loading = true;
      this.$_checkPassword(
        {
          password: this.password,
          userId: this.$AuthUser.id,
        },
      ).then(() => {
        this.loading = false;
        this.visibility.first = false;
        this.visibility.second = true;
        this.$_sendAdminTwoFactorCode({
          userId: this.$AuthUser.id,
          password: this.password,
        })
      }).catch(() => {
        this.loading = false;
        this.error.first = true;
      });
    },

    resendCode() {
      this.resendLoader = true;
      this.$_sendAdminTwoFactorCode({
        password: this.password,
        userId: this.$AuthUser.id
      })
        .then(() => {
          this.resendLoader = false;
        })
        .catch(() => {
          this.resendLoader = false;
          this.error.second = true;
        });
    },

    getEmployees() {
      this.$_getAllOrgUsersWithOptions(`app=humanar&`).then((result) => {
        const adminUsers = result.data.users;
        const usersArr = [];

        adminUsers.filter((a) => {
        if(!a.isRoot && a.isActive) {
          usersArr.push(a);
        }
        return {};
        })

        if(usersArr.length === 0) {
          this.$emit('locked');
        }
        else {
        this.users = usersArr.map((e) => ({
          id: e.id,
          photo: e.photo,
          fname: e.fname,
          lname: e.lname,
          designation: e.userDesignation?.name || ''
        }));
        }
      });
    },

    closeModal() {
        this.password = "";
        this.visibility.first = true;
        this.visibility.second = false;
        this.visibility.third = false;
        this.disableBtn = false;
        this.$emit('close');
    },
  },
  mounted() {
    this.getEmployees();
  }
};
</script>

<style >
.chat-img-x{
      height: 30px;
    margin-right: 12px;
    width: 30px;
    object-fit: cover;
    box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
    border-radius: 5px;
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  text-align: center;
  outline: none;

}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
